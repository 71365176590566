import { FC, SVGProps } from "react"

export const Plus: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M8.83333 1.33331V7.16665H14.6667V8.83331H8.83333V14.6666H7.16666V8.83331H1.33333V7.16665H7.16666V1.33331H8.83333Z"
      fill="#1D1D1B"
      fillRule="evenodd"
    />
  </svg>
)
