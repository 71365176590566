import React, { createContext, useContext, useState } from "react"

export const BgColorContext = createContext<
  { bgColor: string; setBgColor: (color: string) => void } | undefined
>(undefined)

export const BgColorProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [bgColor, setBgColor] = useState("")

  return <BgColorContext.Provider value={{ bgColor, setBgColor }}>{children}</BgColorContext.Provider>
}

export const useBgColor = () => {
  const context = useContext(BgColorContext)
  if (!context) {
    throw new Error("useBgColor must be used within a BgColorProvider")
  }
  return context
}
