import { FC } from "react"

import { Minus } from "../icons/Minus"
import { Plus } from "../icons/Plus"
import { Typography } from "../Typography"

import {
  accordion,
  accordionContent,
  accordionHeadline,
  accordionHeadlineIcon,
  accordionHeadlineTitle,
} from "./styles.css"

type AccordionProps = {
  children: React.ReactNode
  className?: string
  isOpen: boolean
  onToggle: () => void
  title: string
}

export const Accordion: FC<AccordionProps> = ({ children, className, isOpen, onToggle, title }) => {
  return (
    <div className={`${accordion} ${className}`}>
      <div className={accordionHeadline} onClick={onToggle}>
        <Typography className={accordionHeadlineTitle} variant="bodyM">
          {title}
        </Typography>
        {isOpen ? <Minus className={accordionHeadlineIcon} /> : <Plus className={accordionHeadlineIcon} />}
      </div>
      <div className={`${accordionContent} ${isOpen ? "open" : ""}`}>{children}</div>
    </div>
  )
}
