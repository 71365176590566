import { FC, SVGProps } from "react"

export const Minus: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M3.33333 7.33331H12.6667V8.66665H3.33333V7.33331Z"
      fill="#1D1D1B"
      fillRule="evenodd"
    />
  </svg>
)
