import { FC, SVGProps } from "react"

export const Close: FC<SVGProps<SVGSVGElement>> = ({ fill = "#1D1D1B", ...props }) => (
  <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M12.8 2L14 3.2L9.19976 7.99994L14 12.8L12.8 14L7.99994 9.19976L3.2 14L2 12.8L6.79928 7.99994L2 3.2L3.2 2L7.99994 6.79928L12.8 2Z"
      fill={fill}
      fillRule="evenodd"
    />
  </svg>
)
