import { useContext, useEffect } from "react"

import { BgColorContext } from "../utils/context/useBgColor"

import { useIntersectionObserver, UseIntersectionObserverOptions } from "./useIntersectionObserver"

export const useChangeBgColorInView = (color: string, options?: UseIntersectionObserverOptions) => {
  const { isIntersecting, ref } = useIntersectionObserver({
    rootMargin: "-50% 0%",
    ...options,
  })
  const bgColorContext = useContext(BgColorContext)

  useEffect(() => {
    if (bgColorContext && isIntersecting) {
      bgColorContext.setBgColor(color)
    }
  }, [isIntersecting, color, bgColorContext])
  return {
    ref,
  }
}
