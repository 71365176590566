export const ArrowLeft: React.FC<React.SVGProps<SVGSVGElement>> = ({ fill = "#1D1D1B", ...props }) => (
  <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M7.75592 1.33331L1.33332 7.99998L7.75592 14.6666L8.93443 13.4433L4.52517 8.86489L14.6667 8.86498V7.13498L4.52517 7.13488L8.93443 2.55661L7.75592 1.33331Z"
      fill={fill}
      fillRule="evenodd"
    />
  </svg>
)
