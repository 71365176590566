import { H2, vars } from "@casavo/habitat"
import useTranslation from "next-translate/useTranslation"
import React from "react"

import { StepCardList } from "@app/shared/components/StepCardList"
import { sprinkles } from "@app/shared/design-lib/style-theme/sprinkles.css"
import { useChangeBgColorInView } from "@app/shared/hooks/useChangeBgColorInView"
import { fadeInFromBottom } from "@app/shared/styles/js/fadeInFromBottom.css"
import { handleUserEventClick } from "@app/shared/utils/tracking/userEvent"

export const Steps: React.FC = () => {
  const { t } = useTranslation("sell")

  const { ref: targetRef } = useChangeBgColorInView(vars.colors.background.default.light)

  return (
    <section
      ref={targetRef}
      className={`${sprinkles({
        paddingTop: "4xl",
        paddingBottom: { mobile: "xl", desktop: "4xl" },
      })} ${fadeInFromBottom}`}
    >
      <div
        className={sprinkles({
          paddingX: "m",
          textAlign: {
            desktop: "center",
            mobile: "left",
          },
        })}
      >
        <H2 noMargin>{t("sellingsteps_header")}</H2>
      </div>
      <StepCardList
        className={sprinkles({ paddingTop: "3xl" })}
        onSwipe={(nextStepIndex) => {
          handleUserEventClick(`CarouselSellingSteps-SwipeOnCarousel0${nextStepIndex + 1}`)
        }}
      />
    </section>
  )
}
